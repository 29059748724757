body {
  margin: auto;
  max-width: 80%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
h1{
  font-size: 2em;
  font-weight: bold;
  flex: 1 1 100px;
}
h1 span{
  font-size: .6em;
  font-weight: 600;
  opacity: .5;
}

/* Info */
.info-view{
  width: max(30px,1vw);
  height: max(30px,1vw);
  background: #FF7700;
  border-radius: 50%;
  opacity: .3;
  flex: 0 1 max(30px,1vw);
}
.info-view span{
  color: white;
  font-weight: bold;
}
.info-view-modal{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: min(50px, 5vw) 0;
  background: black;
  opacity: .8;
}
.info-view-modal span{
  color: white;
}
/* Grid */
.grid{
  display: flex;
  flex-wrap: wrap;
  top:0;
  bottom: 0;
  width: 100%;
  margin: auto;
  position: relative;
  background-color: black;
  justify-content: space-around;
}
.cell{
  padding: min(8vw, 10vh) 0;
  flex: 1 1 30%;
  color:white;
  font-weight: bold;
  border-style: solid;
  border-width: 1vw;
}
.animate{
  animation: disAfter1 2s ease-out forwards;
}

@keyframes disAfter1 {
  0% {
    opacity: 1;
  }
  100% {
    background: none;
  }
}

@media (max-width: 224px) {
  .grid{
    display: none;
  }
}